import React from 'react';
import styled from '@emotion/styled';
//import Image from 'gatsby-image';
import Modal from 'react-modal';
import { graphql } from 'gatsby';
import Layout from './layout';
import Img from "gatsby-image";
import Carousel from 'react-bootstrap/Carousel';
import { isMobile } from "react-device-detect";
import 'bootstrap/dist/css/bootstrap.min.css';
import Seo from './seo'


export const query = graphql`
    query($id : String!) {
        profile: strapiEntityProfiles ( id: {eq: $id} ) {
            age
            height
            name
            parent_site_url
            rate_five_hours
            rate_one_hour
            rate_two_hours
            title
            video
            description
            drinks
            food
            interest
            languages
            seo_description
            seo_keywords
            seo_title
            services
            en_description
            en_drinks
            en_food
            en_interest
            en_languages
            en_seo_description
            en_seo_keywords
            en_seo_title
            en_services
            category_ass_size {
              name
              en_name
            }
            category_boobs_size {
              name
              en_name
            }
            category_city {
              name
              en_name
            }
            category_contexture {
              name
              en_name
            }
            category_eye_color {
              name
              en_name
            }
            category_hair_color {
              name
              en_name
            }
            category_skin_color {
              name
              en_name
            }
            image_one {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image_two {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image_three {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image_four {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image_five {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image_six {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image_seven {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image_eight {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image_nine {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image_ten {
                id
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
`;

const ProfileContent = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    width: 955;
    text-align: center;
    
    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 2.8fr 1.3fr;
        column-gap: 5rem;
        text-align: left;
    }

`;



const SideBar = styled.aside`
    .profile-detail{
        background-color: #313282;
        padding: 3rem;
        color: #ffcccc;
        text-align: left;
        p {
            font-size: 1.6rem;
            margin: 0;
            font-weight: 600;
            span {
                font-weight: 300;
                color: #bb8888;
            }
        }  
        @media (min-width: 768px) {
            border-radius: 1rem;  
        }
    }
`;

const Spacer = styled.div`
    padding: 0.5rem;
    @media (min-width: 768px) {
        padding: 1rem;  
    }
`;

const ModalVideoButton = styled.button`
    background-color: white; 
    color: #881111; 
    border: 2px solid #881111;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 10rem; 
    &:hover {
        background-color: #f44336;
        color: white;
    }
`;

const LinkDetail = styled.a`
    background-color: white; 
    color: #881111; 
    border: 2px solid #881111;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 10rem; 
    &:hover {
        background-color: #f44336;
        text-decoration: none;
        color: white;
    }
`;

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    },
    overlay: {
        background: "#eaeaea"
    },
    
};

const stylepDescription = {
    fontSize: '16px',
}

const H1Div = styled.div`
    padding: 1rem;
    h1 {
        color: #313282;   
        font-weight: 600; 
    }
`;

const Pcarousel = styled.p`
    color: #FFCCCC;   
    font-size: 1.3rem; 
`;

const Profile = (props) => {

    const lan = props.pageContext.lan;

    const { title, description, age, category_skin_color, category_boobs_size, category_ass_size,
        category_contexture, height, category_city, category_eye_color, category_hair_color, languages,
        interest, food, drinks, rate_one_hour, rate_two_hours, rate_five_hours, services, seo_title, seo_keywords,
        seo_description, en_description, en_drinks, en_food, en_interest, en_languages, en_seo_description, 
        en_seo_keywords, en_seo_title, en_services, video, parent_site_url, image_one, image_two, image_three, 
        image_four, image_five, image_six, image_seven, image_eight, image_nine, image_ten  } = props.data.profile;

    const caorusel = [ image_one, image_two, image_three, image_four, image_five,
        image_six, image_seven, image_eight, image_nine, image_ten ]

    const [modalIsOpen,setIsOpen] = React.useState(false);
    
    function openModal() {
        setIsOpen(true);
    }
       
    function closeModal(){
        setIsOpen(false);
    }

    return ( 
        <Layout lan={lan}>
            <Seo 
                title={ lan === 'es' ? seo_title : en_seo_title }
                keywords={ lan === 'es' ? seo_keywords : en_seo_keywords }
                description={ lan === 'es' ? seo_description : en_seo_description }
            />
            <H1Div>
                <h1>{ title } {  lan === 'es' ? ' es una hermosa dama de  compañía en ' : ' is a beautiful escort in ' } { category_city.name } </h1>
            </H1Div>
            
            <ProfileContent>
                <div>
                    { modalIsOpen ? null :
                        <Carousel>
                         {
                            caorusel.map( item => (
                                item ? <Carousel.Item key={item.id}>
                                    <Img fluid={item.sharp.fluid} alt={  lan === 'es' ? 'Acompañantes Colombia' : 'Colombian companions' } />
                                    <Carousel.Caption>
                                    <h3>{ title }</h3>
                                    <Pcarousel>{ lan === 'es' ? seo_title : en_seo_title }</Pcarousel>
                                    </Carousel.Caption>
                                </Carousel.Item> : null
                            ))
                         }   
                        </Carousel>
                    }
                    <p style={stylepDescription}>{ lan === 'es' ? description : en_description }</p>
                    <ModalVideoButton onClick={openModal}>{ lan === 'es' ? `Ver video de ${title}` : `View ${title} video` }</ModalVideoButton>
                    {/*<LinkDetail href={ parent_site_url }>{ lan === 'es' ? `Ver mas detalles de ${title}` : `View more ${title} details` }</LinkDetail>*/}
                </div>


                
                <SideBar>
                    <div className="profile-detail">
                        <h2>{ lan === 'es' ? 'Perfil': 'Profile' }</h2>
                        <p>{ lan === 'es' ? 'Edad': 'Age' }: <span>{ age }</span></p>
                        <p>{ lan === 'es' ? 'Color de piel': 'Skin color' }:  <span>{ lan === 'es' ? category_skin_color.name : category_skin_color.en_name }</span></p>
                        <p>{ lan === 'es' ? 'Tamaño del busto': 'Boobs size' }: <span>{  lan === 'es' ? category_boobs_size.name : category_boobs_size.en_name   }</span></p>
                        <p>{ lan === 'es' ? 'Tamaño del cola': 'Ass size' }: <span>{ lan === 'es' ? category_ass_size.name : category_ass_size.en_name }</span></p>
                        <p>{ lan === 'es' ? 'Contextura': 'Contexture' }: <span>{ lan === 'es' ? category_contexture.name : category_contexture.en_name }</span></p>
                        <p>{ lan === 'es' ? 'Estatura': 'Height' }: <span>{ height }</span></p>
                        <p>{ lan === 'es' ? 'Ubicación': 'Location' }: <span>{ category_city.name }</span></p>
                        <p>{ lan === 'es' ? 'Color de ojos': 'Eyes color' }: <span>{ lan === 'es' ? category_eye_color.name : category_eye_color.en_name }</span></p>
                        <p>{ lan === 'es' ? 'Color de pelo': 'Hair color' }: <span>{ lan === 'es' ? category_hair_color.name : category_hair_color.en_name }</span></p>
                        <p>{ lan === 'es' ? 'Idiomas': 'Languages' }: <span>{ lan === 'es' ? languages : en_languages }</span></p>
                        <p>{ lan === 'es' ? 'Intereses': 'Interests' }: <span>{ lan === 'es' ? interest : en_interest }</span></p>
                        <p>{ lan === 'es' ? 'Comida': 'Food' }Comida: <span>{ lan === 'es' ? food : en_food }</span></p>
                        <p>{ lan === 'es' ? 'Bebidas': 'Drinks' }Bebidas: <span>{ lan === 'es' ? drinks : en_drinks }</span></p>
                    </div>
                    <Spacer></Spacer>
                    <div className="profile-detail">
                        <h2>{ lan === 'es' ? 'Precios & Servicios': 'Price and Services' }</h2>
                        <p>{ lan === 'es' ? '1 hora': '1 hour' }: <span> $ { new Intl.NumberFormat().format( parseInt(rate_one_hour)) } COP</span></p>
                        <p>{ lan === 'es' ? '2 horas': '2 hours' }: <span> $ { new Intl.NumberFormat().format( parseInt(rate_two_hours)) } COP</span></p>
                        <p>{ lan === 'es' ? '5 horas': '5 hours' }: <span> $ { new Intl.NumberFormat().format( parseInt(rate_five_hours)) } COP</span></p>
                        <p>{ lan === 'es' ? 'Servicios': 'Serives' }: <span>{ lan === 'es' ?  services : en_services }</span></p>
                    </div>
                </SideBar>
            </ProfileContent>
            
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >   <div>
                        <video height={ isMobile ? '200' : '800' } controls>
                            <source src={ video } type="video/mp4" />
                            <source src={ video } type="video/ogg" />
                            <track />
                            { lan === 'es' ?  'Su navegador no soporta el tag de video' : 'Your browser does not support video tag' }
                        </video>
                    </div>
                    <div>
                        <button onClick={closeModal}>{ lan === 'es' ?  'Cerrar' : 'Close' }</button>
                    </div>
                </Modal>
            </div>
        
        </Layout>
    );
}
export default Profile;